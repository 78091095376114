import { Offcanvas, initTE } from 'tw-elements';
import { estimateShippingCost } from './shipping-estimator';
import zipCodeHash from './zip-code-hash.js'
import { currencyFormatter } from '../utils/numbers';
import { getServerCartJSON, cartReponseHandler } from '../utils/cartUtils';

initTE({ Offcanvas });
const operationInProgress: { [key: string]: boolean } = {};

export async function addToCart(variantId: string, quantity: number, properties?: any): Promise<any> {
  try {
    if (operationInProgress[variantId]) {
      console.log(`Operation already in progress for product: ${variantId}`);
      return;
    }
    operationInProgress[variantId] = true;

    let props = properties || {};

    if (props.hasOwnProperty('__is_sample') && props['__is_sample']) {
      let productCount = await countProductsInCartById(variantId);
      if (productCount === 4) {
        throw new Error(`the requested qty exceeds the maximum qty allowed in shopping cart.`);
      }
    }

    const response = await fetch('/cart/add.js', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        items: [
          {
            id: variantId,
            quantity: quantity,
            properties: props,
          },
        ],
      }),
    });

    cartReponseHandler(response);

    const updatedCart = await response.json();
    updateSidebarCart(updatedCart);

    return updatedCart;
  } catch (error: unknown) {
    if (error instanceof Error) {
      window.notificationsManager.open({
        type: window.notificationsManager.types.NOTIFICATION_ERROR,
        body: error.message
      });
    } else {
      console.error('An unexpected error occurred:', error);
      window.notificationsManager.open({
        type: window.notificationsManager.types.NOTIFICATION_ERROR,
        body: 'An unexpected error occurred.'
      });
    }
    return null;
  } finally {
    operationInProgress[variantId] = false; // Reset the operation status
  }
}

export async function addToCartOrUpdate(productCartId: string, quantity: number, properties?: any): Promise<any> {
  try {
    if (operationInProgress[productCartId]) {
      console.log(`Operation already in progress for product: ${productCartId}`);
      return;
    }
    const cart = await getServerCartJSON();

    // Check if the product is already in the cart with same overage
    const existingItem = cart.items.find((item: any) => {
      return parseInt(item.id, 10) === parseInt(productCartId, 10) && item.properties['__overage'] === properties['__overage']
    });

    if (existingItem && existingItem.properties['__overage'] === properties['__overage']) {
      operationInProgress[productCartId] = true;
      // Product is already in the cart, update the quantity and modify properties
      const updatedQuantity = existingItem.quantity + quantity;

      properties['__boxes'] += existingItem.properties['__boxes'];
      properties['__pieces_per_unit'] += existingItem.properties['__pieces_per_unit'];
      properties['__area'] += existingItem.properties['__area'];
      properties['__pallet_area_min'] += existingItem.properties['__pallet_area_min'];
      properties['__pallet_area_max'] += existingItem.properties['__pallet_area_max'];
      properties['__pieces'] += existingItem.properties['__pieces'];

      let endpoint = '/cart/change.js';
      let data: { id: any; quantity: any; properties: any } = {
        id: existingItem.key,
        quantity: updatedQuantity,
        properties: properties,
      };

      if (!properties) {
        // Update the type definition for the 'data' variable
        data = {
          id: existingItem.key,
          quantity: updatedQuantity,
          properties: {},
        };
      }

      // Make a fetch POST call to update the cart
      const updateResponse = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      cartReponseHandler(updateResponse);

      const updatedCart = await updateResponse.json();
      updateSidebarCart(updatedCart);

      return updatedCart;
    } else {
      // Product is not in the cart, add a new item
      addToCart(productCartId, quantity, properties);
    }
  } catch (error: any) {
    // Handle unexpected errors
    window.notificationsManager.open({
      type: window.notificationsManager.types.NOTIFICATION_ERROR,
      body: error.message
    });
    return null;
  } finally {
    operationInProgress[productCartId] = false; // Reset the operation status
  }
}

export async function deleteCartItem(itemKey: string): Promise<any> {

  try {
    if (operationInProgress[itemKey]) {
      console.log(`Operation already in progress for product: ${itemKey}`);
      return;
    }
    operationInProgress[itemKey] = true;
    const cartItemCount = document.getElementsByClassName('js-cart-item').length;
    let response;
    if (cartItemCount === 1) {
      // If there's only one item in the cart, clear the entire cart
      response = await fetch('/cart/clear.js', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });
    } else {
      // Otherwise, remove the specific item by setting its quantity to 0
      const updates = {
        [itemKey]: 0 // Set quantity to 0 to remove the item
      };
      response = await fetch('/cart/update.js', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ updates })
      });
    }

    const updatedCart = await response.json();
    updateSidebarCart(updatedCart);
  } catch (error) {
    console.error('Error deleting cart item:', error);
  } finally {
    operationInProgress[itemKey] = false;
  }

}

export async function countProductsInCartById(productId: any) {
  const cart = await getServerCartJSON();
  let count = 0;
  cart.items.forEach((item: any) => {
    if(parseInt(item.id, 10) === parseInt(productId, 10)) {
      count += item.quantity;
    }
  });
  return count;
}

export async function updateSidebarCart(updatedCart: any, itemKey: string | null = null) {
  try {
    const cart = document.getElementById('cart') as HTMLElement;
    const cartBubble = document.getElementById('js-cart-count') as HTMLElement;
    const sidenavInstance = Offcanvas.getOrCreateInstance(cart);
    const updatedCartCount = updatedCart?.item_count;
    const response = await fetch(`/?section_id=drawer-sidebar`);
    const htmlText = await response.text();

    const content = new DOMParser();

    if (content && htmlText) {
      const parsedContent = content.parseFromString(htmlText, 'text/html');
      const newCartContent = parsedContent.querySelector('.js-cart-wrapper');
      const currentCartWrappers = document.querySelectorAll('.js-cart-wrapper');

      if (newCartContent && currentCartWrappers.length) {
        currentCartWrappers.forEach((cartWrapper) => {
          cartWrapper.innerHTML = newCartContent.innerHTML;
          // Directly remove the item if the count doesn't match
          const cartItems = cartWrapper.getElementsByClassName('js-cart-item');
          const cartCount = cartItems.length;

          if ((cartCount !== updatedCartCount) && itemKey) {
            const itemToRemove = cartWrapper.querySelector(`.cart-item[data-item-key="${itemKey}"]`);
            if (itemToRemove) {
              itemToRemove.remove();
            }
          }

          if (cartCount === 0 || updatedCartCount === 0) {
            setTimeout(() => {
              sidenavInstance.hide();
            }, 1000);
          } else {
            setupDeleteButtonListeners();
            setupEstimateShippingButtonListeners();
            sidenavInstance.show();
          }
          if (cartBubble) {
            cartBubble.innerText = cartCount.toString();
          }
        });
      }
    }
  } catch (error) {
    console.error('Error updating sidebar cart:', error);
  }
}

let cartContentClickListener: ((event: Event) => void) | null = null;

export function setupDeleteButtonListeners() {
  const cartContentContainer = document.querySelector('.js-cart-content');

  if (cartContentClickListener !== null && cartContentContainer) {
    cartContentContainer.removeEventListener('click', cartContentClickListener);
    cartContentClickListener = null;
  }

  cartContentClickListener = (event: Event) => {
    const target = event.target as HTMLElement;
    const deleteButton = target.closest('.js-delete') as HTMLElement;
    if (!deleteButton) return;

    const itemKey = deleteButton.dataset.itemKey;
    if (itemKey) {
      window.notificationsManager.open({
        type: window.notificationsManager.types.NOTIFICATION_WARNING,
        body: 'Are you sure you would like to remove this item from the shopping cart?',
        callback: () => deleteCartItem(itemKey)
      });
    } else {
      console.error('Item key not found.');
    }
  };

  if (cartContentContainer) {
    cartContentContainer.addEventListener('click', cartContentClickListener);
  } else {
    console.error('Cart content container not found.');
  }
}

interface CartProperties {
  [key: string]: any;
  __url?: string;
}

// Event listener for elements with class 'add-to-cart'
export function setupAddToCartButtonListeners() {
  const addToCartButtons = document.querySelectorAll<HTMLElement>('.js-add-to-cart');

  addToCartButtons.forEach(addToCartButton => {
    addToCartButton.addEventListener('click', function (this: HTMLElement) {
      if (this.hasAttribute('data-te-toggle') && this.getAttribute('data-te-toggle') === 'modal') {
        return;
      }

      try {
        const variantId = this.dataset.variantId || null;
        const quantity = parseInt(this.dataset.quantity || '1', 10) || 1;
        let properties: CartProperties = {};
        if (this.dataset.properties) {
          try {
            properties = JSON.parse(this.dataset.properties);
          } catch (error) {
            console.error('Error parsing data-properties:', error);
          }
        }

        // Append the current URL to properties
        properties['__url'] = window.location.href;

        if (variantId) {
          addToCart(variantId, quantity, properties);
        }
      } catch (error) {
        console.error('Error handling add to cart:', error);
      }
    });
  });
}

export function setupEstimateShippingButtonListeners() {
  const estimateShippingLabels = document.querySelectorAll<HTMLElement>('.js-estimate-shipping label');

  estimateShippingLabels.forEach(estimateShippingLabel => {
    estimateShippingLabel.addEventListener('click', handleLabelClick);
  });
}

function handleLabelClick(this: HTMLLabelElement) {
  const inputZipCode = document.getElementById('js-shipping-input') as HTMLInputElement | null;

  if (!inputZipCode) {
    console.error('Input element not found.');
    return;
  }

  inputZipCode.classList.toggle('hidden');
  inputZipCode.classList.toggle('visible');

  if (!inputZipCode.classList.contains('hidden')) {
    inputZipCode.focus();
  }

  inputZipCode.addEventListener('input', handleInput);

  async function handleInput(this: HTMLElement) {
    const shippingValueElement = document.getElementById('js-shipping-value');
    if (shippingValueElement) {
      let zip = inputZipCode?.value || '';
      if (isValidZipCode(zip)) {
        // Extract the first three characters of the ZIP code
        const provinceKey = zip.substring(0, 3);

        // Check if the key exists in zipCodeHash
        if (provinceKey in zipCodeHash) {
          // @ts-ignore
          let address = { zip: zip, province: zipCodeHash[provinceKey] };
          try {
            const data = await estimateShippingCost(address);
            if (data.shipping_rates.length) {
              interface rate {
                name: string;
                price: number;
                source: string;
              }
              const rate = data.shipping_rates.find((rate: rate) => rate.name.includes('Palletized'))?.price || data.shipping_rates[0].price
              shippingValueElement.textContent = currencyFormatter.format(rate);
            }
          } catch (error) {
            console.error('Error fetching shipping rates:', error);
          }
        }
      }
    }
  }
}

function isValidZipCode(zip: string): boolean {
  if (zip.length < 5 || zip === '00000' || zip === '00000-0000') {
    return false;
  }
  const allowedCharsPattern = /^[\dA-Za-z -]+$/;
  const usZipPattern = /^\d{5}(-\d{4})?$/;
  const canPostalCodePattern = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;

  return allowedCharsPattern.test(zip) && (usZipPattern.test(zip) || canPostalCodePattern.test(zip));
}