interface ShippingEstimateParams {
    zip: string;
    province: string;
}
  
export const estimateShippingCost = async ({ zip, province }: ShippingEstimateParams): Promise<any> => {
    const params = new URLSearchParams();
    params.set('shipping_address[zip]', zip);
    params.set('shipping_address[country]', 'United States');
    params.set('shipping_address[province]', province);

    const response = await fetch(`/cart/shipping_rates.json?${params.toString()}`, {
        method: 'GET'
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
}