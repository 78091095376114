
/**
 * @returns {Promise<any>} Promise that resolves to the cart JSON
 */
export async function getServerCartJSON (): Promise<any> {
  // Make a fetch POST call to fetch the current cart
  const cartResponse = await fetch('/cart.js');
  if (!cartResponse.ok) {
    // Handle non-successful response (e.g., 404 error)
    if (cartResponse.status === 404) {
      // You might want to display an error message to the user
      window.notificationsManager.open({
        type: window.notificationsManager.types.NOTIFICATION_ERROR,
        body: 'Cart not found. Please try again later.'
      });
    } else {
      // Handle other errors
      window.notificationsManager.open({
        type: window.notificationsManager.types.NOTIFICATION_ERROR,
        body: 'Error fetching cart. Please try again later.'
      });
    }
    return null; // Or handle the error in a way that makes sense for your application
  }

  return cartResponse.json();
}

/**
 * 
 * @param cartResponse 
 * @returns void
 * @throws Error
 */
export function cartReponseHandler (cartResponse: Response ): void {
  if (!cartResponse.ok) {
    // Handle non-successful response (e.g., 404 error)
    if (cartResponse.status === 404) {
      throw new Error('Item not found. Please check the product and try again.');
    } else {
      // Handle other errors
      throw new Error('Error adding item to cart. Please try again later.');
    }
  }
  return;
}